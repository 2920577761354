import React from "react"
import { StaticQuery, graphql } from "gatsby"

const HistorySection = () => (
	<StaticQuery
		query={graphql`
			query {
				dnhCms {
					historyEvents(where: {status: PUBLISHED}) {
						id
						image {
							url
						}
						years
						heading
						copy
					}
				}
			}
		`}
		render={data => (
			<div className="history-section">
				<div className="container">
					<h2 className="page-title">Our History</h2>
					<div className="timeline-wrap">
						{data.dnhCms.historyEvents.map(event => (
							<div key={event.id} className="timeline-item">
								<div className="timeline-item--left">
									<div className="timeline-item__img" style={{backgroundImage: `url(${event.image.url})`}}></div>
								</div>
								<div className="timeline-item--right">
									<span className="timeline-item__years">{event.years}</span>
									<h4 className="timeline-item__title">{event.heading}</h4>
									<p className="timeline-item__copy">{event.copy}</p>
								</div>
							</div>
						))}
					</div>
				</div>
				<div className="history-line">
					<span className="white"></span>
					<span className="pale-orange"></span>
					<span className="light-orange"></span>
					<span className="light-orange-2"></span>
					<span className="orange"></span>
				</div>
			</div>
		)}
	/>
)
export default HistorySection
