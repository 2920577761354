import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import CurlyLineDot from "./curlyLineDot"
import GraphImg from "graphcms-image"

const MissionSection = () => (
	<StaticQuery
		query={graphql`
			query {
				dnhCms {
					missionSectionButtonses(where: {status: PUBLISHED}) {
						id
						title
						subPage {
							id
							pageTitle
							page{
								pageTitle
							}
						}
					}
					missionSectionTexts(where: {status: PUBLISHED}) {
						id
						heading
						copy
					}
					images(where: {status: PUBLISHED, subPage: {pageTitle: "About us"}}) {
						asset {
							handle
							height
							width
						}
						alt
					}
				}
			}
		`}
		render={data => (
			<div className="mission-wrap container">
				<div className="left-side">
					<GraphImg image={data.dnhCms.images[1].asset} fit="scale" backgroundColor={true} withWebp={true} alt={data.dnhCms.images[1].alt} />
					<GraphImg outerWrapperClassName="image-2" image={data.dnhCms.images[0].asset} fit="scale" backgroundColor={true} withWebp={true} alt={data.dnhCms.images[0].alt} />
				</div>
				<div className="right-side">
					<div className="mission-text-wrap">
						{data.dnhCms.missionSectionTexts.map(text => (
							<div key={text.id} className="mission-texts">
								<CurlyLineDot />
								<h4 className="mission-texts__heading">{text.heading}</h4>
								<p className="mission-texts__copy">{text.copy}</p>
							</div>
						))}
					</div>
					<div className="mission-buttons-wrap">
						{data.dnhCms.missionSectionButtonses.map(btn => (
							<div key={btn.id} className="mission-buttons">
								<CurlyLineDot />
								<h4 className="mission-buttons__title">{btn.title}</h4>
								<Link
									to={`/${btn.subPage.page.pageTitle.replace(/\s+/g, '-').toLowerCase()}/${btn.subPage.pageTitle.replace(/\s+/g, '-').toLowerCase()}`}
									className="mission-buttons__link">
									Find out more
									<i className="fas fa-chevron-right"></i>
								</Link>
							</div>
						))}
					</div>
				</div>
			</div>
		)}
	/>
)
export default MissionSection
